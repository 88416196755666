import React from 'react';

import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Fade from '../components/fade.jsx';
import Button from '../components/button.jsx';
import Layout from '../components/layout.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import FirstMeeting from '../components/first-meeting.jsx';

import usePageContext from '../hooks/use-page-context.js';
import Testimonials from '../components/testimonials.jsx';

export default function Home(props) {
	let { data } = props;
	let { language } = usePageContext();
	let { sanityReferencesPage, allSanityBrainjarReference } = data;
	let { title, seo } = sanityReferencesPage;
	let { nodes: references } = allSanityBrainjarReference;

	let renderReferences = [];
	for (let reference of references) {
		let { company, slug, logo, _rawLanguages: values } = reference;
		let { description } = values[language];

		renderReferences.push(
			<div key={reference.id} className="grid grid-rows-[max-content,1fr,max-content]">
				<Link
					to={slug.current}
					className="relative flex items-center justify-center w-64 h-64 self-x-center self-y-center"
				>
					<Fade opacity="0.25" />
					<img src={logo.asset.url + "?h=200"} className="z-10 w-2/5" alt={`Logo ${company}`} />
				</Link>

				<div className="mt-2 self-y-start">
					<Link
						to={slug.current}
						className="block pb-3 mb-2 text-3xl font-semibold border-b-2 2xl:text-4xl text-primary border-primary"
					>
						{company}
					</Link>
					<p className="mt-6">{description}</p>
				</div>

				<span className="mt-6">
					<Button as="link" to={slug.current} aria-label={`Read more about our case at ${company}`}>
						Read more
					</Button>
				</span>
			</div>,
		);
	}

	return (
		<Layout seo={seo}>
			<Section>
				<div className="grid gap-12 lg:grid-cols-2">
					<h1 className="text-4xl md:text-5xl 2xl:text-6xl self-y-center">
						<TitleBlock title={title} />
					</h1>

					<div className="relative w-full rounded-full lg:h-full lg:w-auto lg:max-w-none">
						<StaticImage src="../images/hero-references.svg" alt="Building" className="transform scale-75" />
					</div>
				</div>
			</Section>

			<Section>
				<div className="grid gap-12 md:grid-cols-2 lg:grid-cols-3 lg:gap-24 xl:gap-32">{renderReferences}</div>
			</Section>

			<Testimonials showTestimonialsCarousel={false} />

			<FirstMeeting />
		</Layout>
	);
}

export const query = graphql`
	query References($language: String!) {
		sanityReferencesPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}
		allSanityBrainjarReference {
			nodes {
				id
				company
				_rawLanguages
				slug {
					current
				}
				logo {
					asset {
						url
					}
				}
			}
		}
	}
`;
